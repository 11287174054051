<template>
    <div class="friendlyLink">
        <div class="txt">友情链接：</div>
        <div class="imgs">
            <a class="item" v-for="(item, index) in imgList" :key="index" :href="item.url" target="_blank">
                <img :src="item.img_url" alt="">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'friendlyLink',

    data() {
        return {
            imgList: [
                {   
                    url: 'https://hnsswt.henan.gov.cn/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/companyLogo/logo_01.png'
                },
                {
                    url: 'http://www.haciq.gov.cn/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/companyLogo/logo_02.png'
                },
                {
                    url: 'http://zhengzhou.customs.gov.cn/default.aspx',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/companyLogo/logo_03.png'
                },
                {
                    url: 'http://zhengzhou.customs.gov.cn/default.aspx',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/companyLogo/logo_04.png'
                },
                {
                    url: 'https://www.maersk.com.cn/appmanager/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/companyLogo/logo_05.png'
                },
                {
                    url: 'https://www.kline.com.cn/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/companyLogo/logo_06.png'
                },
                {
                    url: 'https://www.oocl.com/eng/Pages/default.aspx',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/companyLogo/logo_07.png'
                },
                {
                    url: 'http://www.cma-cgm.com.cn/web/china/',
                    img_url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/companyLogo/logo_08.png'
                },

            ],
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
.friendlyLink {
    height: 175px;
    background: #fff;
    .txt {
        margin-top: 59px;
        font-size: 24px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
    }
    .imgs {
        margin-top: 39px;
        display: flex;
        justify-content: space-between  ;
        .item {
            width: 132px;
            height: 52px;
            background: #FFFFFF;
            border: 1px solid #FFFFFF;
            box-shadow: 0px 4px 16px 0px rgba(171, 171, 171, 0.25);
            border-radius: 4px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>