<template>
    <div class="notification">
        <div class="title">
            <div class="txt">通知公告</div>
            <div class="more" @click="goMore">查看更多</div>
        </div>
        <div class="listBox" v-loading="loading">
            <div v-if="total>0">
                <div class="list" v-for="(item, index) in notificationList" :key="index">
                    <div class="content" @click="goDetail(item.id)">{{item.title}}</div>
                </div>
            </div>
            <noDataImg v-else></noDataImg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'notification',

    data() {
        return {
            total: 0,
            type: 73,
            loading: false,
            notificationList: [],
        };
    },

    mounted() {
        this.getList();
    },

    methods: {
        async getList() {
            this.loading = true
            let params = {
                content_type: this.type,
                source: this.PJSource,
                nopage: 0,
                limit: 5,
                start: 0,
                is_show: 1,
                language: this.LOCALE == "en" ? 1 : 2,
            };
            let res = await this.$store.dispatch(
                "API_index/getStaticContent",
                params
            );
            if (res.success) {
                this.notificationList = res.data
                this.total = res.total
                this.loading = false
            }
        },
        goMore() {
            this.$router.push("/activitys?content_type=73");
        },
        goDetail(id) {
            this.$router.push(`/detail?id=${id}`);
        },
    },
};
</script>

<style lang="less" scoped>
.notification {
    margin-bottom: 39px;
    padding-top: 33px;
    width: 312px;
    height: 377px;
    padding-right: 20px;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .txt {
            font-size: 24px;
            font-weight: 400;
            color: #1018A1;
            line-height: 24px;
        }
        .more {
            font-size: 14px;
            font-weight: 400;
            color: #1018A1;
            opacity: 0.8;
            cursor: pointer;
        }
    }
    .listBox {
        position: relative;
        height: 320px;
        margin-top: 4px;
        .list {
            height: 64px;
            border-bottom: 1px solid #eee;
            overflow: hidden;
            .content {
                width: 275px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 14px;
                margin-top: 25px;
                cursor: pointer;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
            }
            .content:hover {
                color: #0E17AE;
            }
        }
    }
}
</style>