<template>
    <div class="talent">
        <div class="title">
            <div class="txt">人才专区</div>
            <div class="more" @click="goMore">查看更多</div>
        </div>
        <div class="listBox" v-loading="loading">
            <div class="list" v-for="(item, index) in talentList" :key="index">
                <div class="attache" @click="goToPositionDetail(item.id)">{{item.name}}<span> — </span>{{item.city_name_zh}}</div>
                <div class="company" :title="item.name_zh">{{item.name_zh}}</div>
            </div>
            <noDataImg class="noDataImg" v-if="!loading && talentList.length === 0"></noDataImg> 
        </div>   
    </div>
</template>

<script>
export default {
    name: 'talentZone',

    data() {
        return {
            loading: false,
            talentList: [],
        };
    },

    mounted() {
        this.getList();
    },

    methods: {
        async getList() {
            // 获取职位推荐
            this.loading = true;
            let parmas = {
                start: 0,
                limit: 6,
                is_hot: 1,
                source: this.PJSource,
            };
            let data = await this.$store.dispatch(
                "API_position/getJobPositionList",
                parmas
            );
            this.loading = false;
            if (data.success) {
                this.talentList = data.data;
            }
        },
        goToPositionDetail(id) {
            let res = this.$router.resolve({
                path: "/positionDetail",
                query: {
                parameter: this._encode({
                    id: id,
                }),
                },
            });
            window.open(res.href, "_blank");
        },
        goMore() {
            this.$router.push("/talents/recruitment");
        },
    },
};
</script>

<style lang="less" scoped>
.talent{
    height: 281px;
    width: 580px;
    position: relative;
    .title {
        margin-bottom: 23px;
        display: flex;
        justify-content: space-between;
        .txt {
            font-size: 24px;
            line-height: 24px;
            font-weight: 400;
            color: #0E17AE;
        }
        .more {
            font-size: 14px;
            font-weight: 400;
            color: #1018A1;
            opacity: 0.8;
            cursor: pointer;
        }
    }
    .listBox {
        position: relative;
        height: 234px;
        .list {
            display: flex;
            justify-content: space-between;
            
            .attache {
                margin-bottom: 30px;
                font-size: 14px;
                line-height: 14px;
                width: 300px;
                white-space:nowrap;
                text-overflow:ellipsis;
                overflow: hidden;
            }
            .attache:hover {
                color: #0E17AE ;
                cursor: pointer;
            }
            .company {
                color: #0E17AE;
                font-size: 14px;
                width: 210px;
                white-space:nowrap;
                text-overflow:ellipsis;
                overflow: hidden;
                line-height: 14px;
            }
        }
    }
}
</style>