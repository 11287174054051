<template>
    <div class="container">
        <carouselBanner paddingTop="24%" :bannerType="1"></carouselBanner>
        
        <div class="content_area">
            <div class="flexBox">
                <Notification></Notification>
                <NewsExpress></NewsExpress>
                <ForwarderKnowledge></ForwarderKnowledge>

            </div>
            <!-- 政策法规、考试培训、企业资信 -->
            <div>
                <Activity></Activity>
            </div>
            <!-- 会议动态、下载专区 -->
            <div class="flexBox">
                <SpecialCampaign></SpecialCampaign>
                <TalentZone></TalentZone>
            </div>
            
        </div>
        <!-- 教育培训 -->
        <div class="cooperation">
            <div class="content_area">
            <Education></Education>
            </div>
        </div>
        <!-- 友情链接 -->
        <div class="content_area">
            <FriendlyLink></FriendlyLink>
        </div>
        <!-- 申请入会 -->
        <div class="img">
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/initiation.gif" alt="" @click="goMemberShip">
        </div>
    </div>
</template>

<script>

import carouselBanner from "@/baseComponents/carouselBanner"
import NewsExpress from "~hns/components/index/home/newsExpress"
import Notification from "~hns/components/index/home/notification"
import ForwarderKnowledge from "~hns/components/index/home/forwarderKnowledge"
import Activity from '~hns/components/index/home/activity'
import TalentZone from "~hns/components/index/home/talentZone"
import SpecialCampaign from "~hns/components/index/home/specialCampaign"
import Education from "~hns/components/index/home/education";
import FriendlyLink from "~hns/components/index/home/friendlyLink";

export default {
    metaInfo() {
    return {
        title: '河南省国际货运代理协会官网',
        };
    },
    props: {},
    components: {
        Notification,
        NewsExpress,
        ForwarderKnowledge,
        Activity,
        carouselBanner,
        TalentZone,
        SpecialCampaign,
        Education,
        FriendlyLink
    },
  data () {
   return {}
  },
  computed: {},
  watch: {},
  methods: {
      goMemberShip() {
          this.$router.push("/memberShip");
      }

  },

  created () {
  },
  mounted () {
  },
}
</script> 

<style scoped lang="less">
    .flexBox {
        display: flex;
        justify-content: space-between;
    }
    .container {
        background-color: #fff;
        
        .cooperation {
            width: 100%;
            height: 612px;
            background: url(https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/hns/bg_educational.png);
        }
        .img {
            width: 206px;
            height: 188px;
            position:fixed;
            right: 0;
            bottom: 45%;  
            z-index: 9999;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

</style>