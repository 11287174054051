<template>
  <div class="activity">
    <div class="top">
      <div class="row">
        <div 
          @click="handleEvent(4)"
          :class="type == 4 ? 'active' : ''" 
          >政策法规</div>
        <div 
          @click="handleEvent(76)"
          :class="type == 76 ? 'active' : ''" 
          >考试培训</div>
        <div 
          @click="handleEvent(changeType)"
          :class="type == changeType ? 'active' : ''" 
          >{{changeTitle}}
          <i  
          style="font-size:14px;color:#1018A1;" 
          :class="icon ?'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>  
        </div>
        <div class="col" v-if="icon">
          <div 
            :class="(type == 77 || type == 78) ? 'active' : ''"
            class="title"
            @click="iconOne=!iconOne"
            >
            业务备案情况
            <i  
            style="font-size:14px;color:#1018A1;" 
            :class="iconOne ?'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <div v-if="iconOne">
            <div
              :class="type == 77 ? 'activeBg':''"
              @click="changeEvent(77)"
              class="title">
              已备案企业</div>
            <div 
              :class="type == 78 ? 'activeBg':''"
              @click="changeEvent(78)"
              class="title"
              >未备案企业</div>
          </div>
          <div 
            :class="(type == 79 || type == 80) ? 'active' : ''"
            @click="iconTwo=!iconTwo"
            class="title">
            会费缴纳情况
            <i  
            style="font-size:14px;color:#1018A1;" 
            :class="iconTwo ?'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
          </div>
          <div v-if="iconTwo">
            <div 
              :class="type == 79 ? 'activeBg':''"
              @click="changeEvent(79)"
              class="title">
              已交纳企业</div>
            <div 
              :class="type == 80 ? 'activeBg':''"

              @click="changeEvent(80)"
              class="title">
              未交纳企业</div>
          </div>
          <div 
            :class="type == 81 ? 'activeBg' : ''"
            class="title"
            @click="changeEvent(81)"
            >企业资质及获得表彰情况</div>
        </div>
      </div>
      <div class="more" @click="goMore">查看更多</div>
    </div>
    <div class="bottom">
      <div class="listBox" v-loading="loading">
        <div v-if="total>0">
          <div class="left fl" @click="$router.push(`/detail?id=${one[0].id}`)">
            <img :src="one[0].img_url || imgUrl" alt="" />
            <div class="txt">
              <div class="title">
              {{ one[0].title }}
              </div>
              <div class="time">{{ one[0].created_time | secondFormat("LL") }}</div>
            </div>
          </div>
          <div class="right fl">
            <div class="txt" v-for="(item, index) in activityList" :key="index">
              <div class="title" @click="goDetail(item.id)">{{ item.title }}</div>
              <div class="time">{{ item.created_time | secondFormat("LL") }}</div>
            </div>
          </div>
        </div>
        <noDataImg v-else></noDataImg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'activity',

  data() {
    return {
      changeTitle:'企业资信',
      changeType: 0,
      icon: false,
      iconOne: false,
      iconTwo: false,
      isTitle: false, //是否在上方标题，在点击不发送请求，不在发送请求
      type: 4,
      loading: false,
      one:[],
      activityList: [],
      total: 0,
      img_url: '',
      imgUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/AssociationNews.png',
       
    };
  },

  mounted() {
    this.getActivityList(4)
  },

  methods: {
    handleEvent(type) {
      this.type = type
      if(
        this.type == 0 ||
        (this.type == 77 && this.isTitle) ||
        (this.type == 78 && this.isTitle) ||
        (this.type == 79 && this.isTitle) ||
        (this.type == 80 && this.isTitle) ||
        (this.type == 81 && this.isTitle)) {
        this.icon = !this.icon
        return
      } 
      this.icon = false

      this.getActivityList(type)
    },
    changeEvent(changeType) {
      if(changeType == 77) {
        this.changeTitle = "已备案企业"
      }
      if(changeType == 78) {
        this.changeTitle = "未备案企业"
      }
      if(changeType == 79) {
        this.changeTitle = "已交纳企业"
      }
      if(changeType == 80) {
        this.changeTitle = "未交纳企业"
      }
      if(changeType == 81) {
        this.changeTitle = "企业资质及获得表彰情况"
      }
      this.changeType = changeType
      this.isTitle = false
      this.handleEvent(this.changeType)
      this.isTitle = true
      this.icon = false
    },
    async getActivityList(type) {
      this.loading = true
        let params = {
            content_type: type,
            source: this.PJSource,
            nopage: 0,
            limit: 6,
            start: 0,
            is_show: 1,
            language: this.LOCALE == "en" ? 1 : 2,
        };
        let res = await this.$store.dispatch(
            "API_index/getStaticContent",
            params
        );
        if (res.success) {
          this.one = res.data.splice(0, 1);
          this.activityList = res.data
          this.total = res.total
          this.loading = false
        }
    },
    
    
    goMore() {
      let pathInfo = this.$router.resolve({
        path:'/activitys',
          query:{
              content_type: this.type
          }
      })
      window.open(pathInfo.href, '_blank');
    },
    goDetail(id) {
      let pathInfo = this.$router.resolve({
        path:'/detail',
          query:{
              id
          }
      })
      window.open(pathInfo.href, '_blank');
    }
  },
};
</script>

<style lang="less" scoped>
  .activity {
    margin-bottom: 60px;
    .top {
      height: 64px;
      border-bottom: 1px solid #EEEEEE;
      position: relative;
      display: flex;
      justify-content: space-between;
      .row {
        display: flex;
        div {
          line-height: 64px;
          margin-right: 59px;
          font-size: 24px;
          font-weight: 400;
          cursor: pointer;
        }
        .col {
          position: absolute;
          width: 260px;
          top: 64px;
          left: 312px;
          z-index: 99999;
          background: #FFFFFF;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
          overflow: hidden;
          .title {
            width: 260px;
            text-align: center;
            font-size: 20px !important;
            cursor: pointer;
          }
          .title:hover {
            color:  #666666;
            background-color: #ecedf9;
          }
        }
      }
      .more {
        font-size: 14px;
        font-weight: 400;
        color: #1018A1;
        line-height: 64px;
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .bottom {
      .listBox {
        height: 400px;
        position: relative;
        margin-top: 20px;
        .left {
          width: 600px;
          height: 400px;
          margin-right: 41px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .txt {
            position: absolute;
            bottom: 0;
            width: 600px;
            height: 80px;
            background: #0E17AE;
            opacity: 0.8;
            padding-left: 20px;
            .title {
              width: 331px;
              line-height: 14px;
              font-size: 14px;  
              font-weight: 400;
              margin-top: 21px;
              color: #FFFFFF;
              cursor: pointer;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            .time {
              margin-top: 15px;
              font-size: 14px;
              line-height: 14px;
              font-weight: 400;
              color: #FFFFFF;
              opacity: 0.6;
            }
            
          }
        }
        .right {
          .txt {
            height: 80px;
            width: 559px;
            border-bottom: 1px solid #EEEEEE;
            padding-top: 18px;
            .title {
              width: 336px;
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              line-height: 14px;
              cursor: pointer;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: break-all;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
            .title:hover {
              color: #0E17AE;
            }
            .time {
              margin-top: 17px;
              font-size: 14px;
              font-weight: 400;
              color: #999999;
              line-height: 14px;
            }
          }
        }
      }
    }
  }
  .active{
    color: #0E17AE !important;
  }
  .activeBg {
    background-color: #0E17AE !important;
    color: #fff !important;
  }
</style>