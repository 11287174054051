<template>
  <div class="box" >
    <table></table>
    <div class="title">
      <div class="txt" @click="handleMore">教育培训</div>
    </div>
    
    <el-carousel height="403px" :autoplay="false" trigger="click" arrow="never" :indicator-position="indicatorShow">
      <el-carousel-item v-for="(itemList, i) in dataList" :key="i">
        <div class="list">
          <div class="item" v-for="(item, index) in itemList" :key="index">
            <div class="img pointer img-wrap">
              <al-image
                :src="item.cover_url"
                fit="scale-down"
                class="item-img"
              ></al-image>
              <div class="mark">
                <i
                  class="el-icon-video-play cursor"
                  @click="handleContent(item)"
                ></i>
              </div>
            </div>
            <div class="item-txt">
              {{item.name}}
            </div>
          </div>
          <i></i>
          <i></i>
          <i></i>
        </div>
      </el-carousel-item>
    </el-carousel>
    <videoPlay ref="vidoePlay"></videoPlay>
  </div>
</template>

<script>
import videoPlay from "@/baseComponents/videoPlay";
export default {
  name: "education",
  components: {
    videoPlay,
  },
  data() {
    return {
      indicatorShow:'none',
      dataList: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    async getDataList() {
      this.loading = true;
      let params = {
        source: this.PJSource,
        start: 0,
        home_flag: 1,
      };
      let res = await this.$store.dispatch(
        "API_company/association_video_List",
        params
      );
      this.loading = false;
      if (res.success) {
        this.dataList = res.data;
        this.total = res.total
        if (res.total && res.total>3) {
          this.indicatorShow = ''
        }
        let newDataList = []
        let current = 0
        if(this.dataList && this.dataList.length>0){
          for(let i=0;i<=this.dataList.length-1;i++){
            if(i%3 !== 0 || i === 0 ){
              if(!newDataList[current]){
                newDataList.push([this.dataList[i]])
              }else{
                newDataList[current].push(this.dataList[i])
              }
            }else{
              current++
              newDataList.push([this.dataList[i]])
            }
          }
        }
        this.dataList = [...newDataList]
      }
    },
    handleMore() {
      this.$router.push('/education_index')
    },
    handleContent(itme) {
      if (this.IS_LOGIN) {
        this.$refs.vidoePlay.startPlay(itme.aliyun_id);
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$refs.vidoePlay.startPlay(itme.aliyun_id);
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.box {
  margin-top: 82px;
  .title {
    display: flex;
    justify-content:center;
    margin-top: 58px;
    .txt {
      font-size: 28px;
      font-weight: bold;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
  .list {
    height: 265px;
    margin-top: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .item {
      width: 384px;
      height: 216px;
      .img {
        width: 100%;
        margin-bottom: 13px;
        height: 100%;
        overflow: hidden;
        .img-img {
          width: 100%;
          height: 100%;
        }
      }
      .item-txt {
        line-height: 17px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
    & > i {
      width: 384px;
    }
    .item:nth-child(2) {
      width: 400px;
      height: 232px;
      .img {
        margin-bottom: 16px;
      }
    }
  }
}
.pointer {
  cursor: pointer;
}
.img-wrap {
  position: relative;
  .mark {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    line-height: 170px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-icon-video-play {
      font-size: 48px;
      color: #aaa;
    }
  }
  .mark:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  .mark:hover .el-icon-video-play {
    color: #fff;
  }
}
/deep/.el-carousel__indicator--horizontal {
  padding: 0 9px;
}
/deep/.el-carousel__indicator--horizontal .el-carousel__button {
  width: 12px;
  height: 12px;
  background: #9394B7;
  border: 1px solid #9394B7;
  border-radius: 50%;
  opacity: 0.5;
}  
/deep/.el-carousel__indicator--horizontal.is-active .el-carousel__button{
    width: 12px;
    height: 12px; 
    background: #fff;
    border-radius: 50%;
    opacity: 1;
  }
</style>