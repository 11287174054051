<template>
    <div class="forwarderKnowledge">
        <div class="title">
            <div class="txt">货代知识</div>
            <div class="more" @click="goMore">查看更多</div>
        </div>
        <div class="listBox" v-loading="loading">
            <div v-if="total>0">
                <div class="top" @click="$router.push(`/detail?id=${one[0].id}`)">
                    <img :src="one[0].img_url || imgUrl" alt="" />
                    <div>
                        <div class="tit">
                        {{ one[0].title }}
                        </div>
                        <div class="time">{{ one[0].created_time | secondFormat("LL") }}</div>
                    </div>
                </div>
                <div class="bottom">
                    <div
                        class="item"
                        v-for="(item, index) in forwarderList"
                        :key="index"
                        @click="goDetail(item.id)"
                    >
                        <div class="txt">{{ item.title }}</div>
                        <div class="time">{{ item.created_time | secondFormat("LL") }}</div>
                    </div>
                </div>
            </div>
            <noDataImg v-else></noDataImg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'newsExpress',

    data() {
        return {
            total: 0,
            type: 75,
            one: [],
            loading: false,
            forwarderList: [],
            imgUrl: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/AssociationNews.png'
        };
    },

    mounted() {
        this.getList();
    },

    methods: {
        async getList() {
            this.loading = true
            let params = {
                content_type: this.type,
                source: this.PJSource,
                nopage: 0,
                limit: 4,
                start: 0,
                is_show: 1,
                language: this.LOCALE == "en" ? 1 : 2,
            };
            let res = await this.$store.dispatch(
                "API_index/getStaticContent",
                params
            );
            if (res.success) {
                this.one = res.data.splice(0, 1);
                this.forwarderList = res.data
                this.total = res.total
                this.loading = false
            }
        },
        goMore() {
            this.$router.push("/activitys?content_type=75");
        },
        goDetail(id) {
            this.$router.push(`/detail?id=${id}`);
        },
    },
};
</script>

<style lang="less" scoped>
.forwarderKnowledge {
    width: 444px;
    height: 381px;
    padding:33px 20px 0 20px;
    background-color: #EFEFEF;
    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .txt {
            font-size: 24px;
            font-weight: 400;
            color: #1018A1;
            line-height: 24px;
        }
        .more {
            font-size: 14px;
            font-weight: 400;
            color: #1018A1;
            opacity: 0.8;
            cursor: pointer;
        }
    }
    .listBox {
        position: relative;
        height: 320px;
        .top {
            margin-top: 29px;
            display: flex;
            img {
                width: 148px;
                height: 116px;
                margin-right: 14px;
            }
            .tit {
                margin: 7px 0 25px 0;
                width: 228px;
                height: 52px;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                line-height: 26px;
                cursor: pointer;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }
            .tit:hover {
                color: #0E17AE;
            }
            .time {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                line-height: 32px;
            }
        }
        .bottom {
            height: 179px;
            .item {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #e2dddd;
                .txt {
                    width: 233px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 14px;
                    margin: 22px 0;
                    cursor: pointer;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                }
                .txt:hover {
                    color: #0E17AE;
                }
                .time {
                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                    line-height: 58px;
                }
            }
            .item:nth-child(3) {
                border: none;
            }
        }
    }
}
/deep/.el-loading-mask {
    background-color: rgba(255,255,255,0);
}
</style>